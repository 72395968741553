import axiosApi from '@/api/axiosApi';

export const getPersonalInfo = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/company/getPersonalInfo',
  data,
});

export const disassociate = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/company/disassociate',
  data
})

export const allCompanys = () => axiosApi({
  method: 'get',
  url: '/intserv/company/allCompanys'
})

export const bindApply = (data) => axiosApi({
  method: 'post',
  url: '/intserv/company/bindApply',
  data
})