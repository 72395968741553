<template>
  <div class="presonal" :class="{'cente': showSuccess}">
    <template v-if="!showSuccess">
      <div class="page-content">
        <span class="title">
          Personal Information
        </span>
        <table border="1">
          <tr>
            <td rowspan="5"><div>Personal Profile</div><div><span class="t-click" @click="status = 'edit'">Edit</span></div></td>
            <td style="text-align: center;">Email/Account</td>
            <td>{{ form.email }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td v-if="status === 'show'">{{ form.name }}</td>
            <td v-else><input type="text" v-model="form.name"/></td>
          </tr>
          <tr>
            <td>Job Title</td>
            <td v-if="status === 'show'">{{ form.workDescribe }}</td>
            <td v-else>
              <el-select v-model="form.workDescribe" clearable filterable style="width: 100%;">
                  <el-option v-for="(option, index) in JobTitle" :key="index" :label="option.name" :value="option.code"></el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td>Phone number</td>
            <td v-if="status === 'show'">{{ form.mobile }}</td>
            <td v-else><input type="text" v-model="form.mobile"/></td>
          </tr>
          <tr>
            <td>Country/Region</td>
            <td v-if="status === 'show'">{{ form.country }}</td>
            <td v-else>
              <el-select v-model="form.country" clearable filterable style="width: 100%;">
                  <el-option v-for="(item, index) in countryArr" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </td>
          </tr>

          <tr>
            <td rowspan="3"><div>My Company Info</div></td>
            <td style="text-align: center;">Company Name</td>
            <td>
              <div style="width: 100%; display: flex;">
                <div style="width: 50%;">
                  <div v-if="company.companyFullName">{{ company.companyFullName }}</div>
                  <div v-else>
                    <el-select v-model="companyName" clearable filterable style="width: 100%;">
                        <el-option v-for="(item, index) in companys" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </div>
                </div>
                <div style="width: 50%;">
                  <div v-if="company.companyFullName"><span class="link-btn" @click="disassociate">Disassociate</span></div>
                  <div v-else style="height: 100%; line-height: 40px;"><span class="link-btn" @click="bindApply">Associate</span></div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Company administrator</td>
            <td>{{ company.companyManager }}</td>
          </tr>
          <tr>
            <td>Administrator Account</td>
            <td>{{ company.adminAccount }}</td>
          </tr>
        </table>
        <div class="table-bottom">
          <div class="opt-bar">
            <el-button v-if="status === 'edit'" type="primary" round @click="updateSomeInfo">Submit</el-button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="showSuccess">
        <div class="success-panel">
            <img class="success-panel-icon" src="../../assets/image/login/icon_success.png" alt="success">
            <div class="success-panel-title">{{$i18n.t('register.successPanelTitle')}}</div>
            <p class="success-panel-text">{{$i18n.t('bind.successPanelText')}}</p>
            <el-button type="primary" @click="back">{{$i18n.t('register.back')}}</el-button>
        </div>
    </template>
  </div>
</template>

<script>
import {initDicts} from '@/utils/commons';
import { updateSomeInfo } from '@/api/userApi';
import { getPersonalInfo,disassociate,allCompanys,bindApply } from '@/api/company';
import countrys from '@/utils/country.json';
export default {
name: 'PersonalCenter',
data() {
    return {
        status: 'show',
        form: {},
        company: {
          companyFullName: null,
          companyManager: null,
          adminAccount: null
        },
        JobTitle: [],
        countryArr: [],
        companyName: '',
        companys: [],
        showSuccess: false
    }
},
created() {
  if (!this.$store.state.client.user.id) {
    this.$router.push({
      path: '/home'
    })
  }
},
mounted() {
  if (this.$store.state.client.user.id) {
    this.countryArr = countrys.country.map(item => {
        return {label: item, value: item}
    });
    this.getPersonalInfo();
    initDicts(['JobTitle']).then(res => {
      this.JobTitle = res.JobTitle;
    });
    this.getCompanys();
  }
},
methods: {
  // 解除绑定的公司
  async disassociate() {
    const h = this.$createElement;
    if (this.form.email === this.company.companyManager) {
      this.$confirm('',{
        title: 'Tips',
        message: h('div', null, [
          h('p', null, [
              h('span', null, 'Before disassociation, please contact '),
              h('i', { style: 'color: #d2333c' }, 'website@holowits.com '),
              h('span', null, 'to transfer the company administrator privileges to another member (please provide his/her email).')
          ]),
          h('p', null, 'After disassociation, you may lose the management privileges and other associated rights for the company. If you need to re-associate with the company, you are required to fill out the application form.'),
          h('p', null, [
              h('span', null, 'For any questions or assistance, please feel free to contact '),
              h('i', { style: 'color: #d2333c' }, 'website@holowits.com '),
              h('span', null, 'for support.')
          ])
        ])
      }).then(_ => {}).catch(_ => {});
      return false;
    } else {
      this.$confirm('',{
        title: 'Tips',
        message: h('div', null, [
          h('p', null, "After disassociation, you may lose access to the company's information, resources, and functionalities on HOLOWITS website. Please confirm if you want to proceed with disassociation. "),
          h('p', null, "If you need to re-associate with the company, you are required to fill out the application form. "),
          h('p', null, [
              h('span', null, 'For any questions or assistance, please feel free to contact '),
              h('i', { style: 'color: #d2333c' }, 'website@holowits.com '),
              h('span', null, 'for support.')
          ])
        ])
      }).then(_ => {
        this.disassociateApi()
      }).catch(_ => {});
    }
  },

  async bindApply() {
    if (this.companyName) {
      this.$confirm(`Please confirm if you want to associate with (${this.companyName}).`).then(_ => {
        this.bindApplyApi()
      }).catch(_ => {

      })
    }
  },
  async bindApplyApi() {
    const {data: {data, isSuccess}} = await bindApply({
      userId: this.form.id,
      account: this.form.email,
      name: this.form.name,
      companyFullName: this.companyName
    })
    if (isSuccess) {
      this.showSuccess = true
    }
  },
  back() {
    this.showSuccess = false
  },
  async disassociateApi() {
    const {data: {data, isSuccess}} = await disassociate({
      companyId: this.company.id,
      userId: this.form.id
    })
    if (isSuccess) {
      this.$message.success('Success');
      this.company = {
        companyFullName: null,
        companyManager: null,
        adminAccount: null
      };
    }
  },

  async getCompanys() {
    const {data: {data, isSuccess}} = await allCompanys()
    if (isSuccess) {
      this.companys = data.map(i => {
        return {label: i, value: i}
      })
    }
  },

  async updateSomeInfo() {
    const {data: {data, isSuccess}} = await updateSomeInfo({
      id: this.$store.state.client.user.id,
      name: this.form.name,
      mobile: this.form.mobile,
      workDescribe: this.form.workDescribe,
      country: this.form.country
    })
    if (isSuccess) {
      this.$message.success('Success');
      this.status = 'show'
    }
  },
  async getPersonalInfo() {
    const {data: {data, isSuccess}} = await getPersonalInfo({
      userId: this.$store.state.client.user.id
    })
    if (isSuccess) {
      this.$message.success('Success');
      this.form = data.partner;
      if (data.company) {
        this.company = data.company;
      }
    }
  }
}
}
</script>

<style lang="scss" scoped>
.cente {
  display: flex;
  justify-content: center;
  align-items: center;
}
.presonal {
  min-height: calc(100vh - 162px - 100px);

  .success-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .success-panel-icon {
      width: 88px;
      margin-bottom: 10px;
    }

    .success-panel-title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }

    .success-panel-text{
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      max-width: 500px;
      margin: 30px 0 50px;
    }

    .el-button{
      display: block;
      width: 307px;
      max-width: 100%;
      height: 66px;
      font-size: 18px;
      border-radius: 8px;
    }
  }
  .page-content {
    width: 1170px;
    margin: auto;
    .title {
      font-size: 24px;
      font-weight: bold;
      border-bottom: 2px solid #c7000b;
    }

    .t-click {
      cursor: pointer;
      color: #c7000b;
      &:hover {
        border-bottom: 1px solid #c7000b;
      }
    }

    table {
      width: 80%;
      margin-top: 50px;
      border-color: #a9a9a9;
      tr {
        height: 50px;
        td {
          input {
            outline: none;
            height: 40px;
            width: 100%;
            border: none;
            padding: 0 15px;
            // padding-bottom: 4px;
            border-bottom: 1px solid #000;
            text-align: left;
          }
          .select {
            outline: none;
            height: 40px;
            line-height: 40px;
            width: 100%;
            border: none;
            border-bottom: 1px solid #000;
            text-align: left;
            position: relative;
            .option {
              background-color: #fff;
              position: absolute;
              top: 42px;
              left: 0px;
              width: 100%;
              box-shadow: 0px 0px 1px 0px grey;
              ul {
                height: 300px;
                overflow: auto;
                li {
                  height: 30px;
                  line-height: 30px;
                  padding: 0 20px;
                  cursor: pointer;
                  &:hover {
                    background-color: #f9e6e7;
                  }
                }
              }
              .active {
                background-color: #f9e6e7;
              }
            }
            .is-none {
              display: none;
            }
          }

          ::v-deep .el-input__inner {
            border: none;
            border-bottom: 1px solid #000;
            border-radius: 0px !important;
          }

          .link-btn {
            cursor: pointer;
            color: #c7000b;
            border-bottom: 1px solid #c7000b;
            margin-left: 40px;
          }

          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 20%;
            padding: 0 20px;
            text-align: left;
          }
          &:nth-child(3) {
            width: 60%;
            padding: 0 20px;
            text-align: left;
          }
          text-align: center;
        }
      }
    }

    ::v-deep .el-button.is-round {
      padding: 12px 50px;
    }

    .table-bottom {
      width: 80%;
      margin-top: 20px;
      .opt-bar {
        width: 60%;
        height: 40px;
        padding: 10px 0;
        float: right;
        text-align: center;
      }
    }

    .title2 {
      padding: 10px 0;
      color: rgb(117, 115, 115);
      font-size: 16px;
      font-weight: bold;
    }
    .item {
      display: flex;
      justify-items: center;
      .title3 {
        padding-left: 10px;
        font-size: 14px;
        font-weight: bold;
      }
      .value {
        margin-left: 20px;
        min-width: 300px;
        padding-bottom: 5px;
        border-bottom: 2px solid #000;
      }
    }
  }
}
</style>